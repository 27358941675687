const { colors } = require("./src/styles/colors");
const { isWeb } = require("./src/tools/generic");

global.host = __DEV__ ? "192.168.50.136:8000" : "www.rolegate.com";
global.root_url = __DEV__ ? `http://${global.host}` : `https://${global.host}`;
global.ws_scheme = __DEV__ ? "ws" : "wss";


global.delayLongPress = __DEV__ ? 1000 : null;
global.savedStoreVersion = 2;
global.appVersionMajor = 1;
global.appVersionMinor = 226;
global.appVersionString = global.appVersionMajor + "." + global.appVersionMinor; // used to keep the "0"s at the end (ex: 1.200 vs 1.2)
global.appVersion = Number(global.appVersionMajor + "." + global.appVersionMinor);
global.mandatoryVersionMajor = 0;
global.mandatoryVersionMinor = 0;
global.lastNewsVersion = 0.26;
global.expoTokenVersion = 1;

global.colors = colors
global.appleStoreReview = !isWeb();
global.allowLiveUpdate = true;

// global.host = "www.rolegate.com";
// global.root_url = "https://www.rolegate.com";
// global.ws_scheme = "wss";

// global.host = "127.0.0.1:8000";
// global.root_url = `http://${global.host}`;
// global.ws_scheme = "ws";
