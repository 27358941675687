import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { ActivityIndicator, AppState } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { apiFetch } from "../../network/apiFetch";
import { socket } from "../../network/websocket";
import MCVPFlatList from "../../reimports/MCVPFlatList";
import { saveBookmark } from "../../store/slices/bookmarksSlice";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import {
	setCurrentStoryMarker,
	setFocusedType,
	setHasPartiesOnScreen,
	setLoadingMoreLines
} from "../../store/slices/gamesUISlice";
import { fetchLine, fetchLines, fetchNextLines, fetchPreviousLines, removeLines } from "../../store/slices/linesSlice";
import store from "../../store/store";
import { gameScreenPadding } from "../../styles/dynamicStyles";
import { isInGame, isPlayer } from "../../tools/games";
import { hasValue, idEqual, isAndroid, isWeb, ws } from "../../tools/generic";
import { findIndexOfIdClosestTo, getLinesDisplayed, isStory } from "../../tools/lines";
import { usePrevious } from "../../tools/react";
import { getLastScene, getMostRecentScene } from "../../tools/storyMarker";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import CondView from "../meta/CondView";
import JumpToBottomButton from "./JumpToBottomButton";
import LineListBottomComponent from "./LineListBottomComponent";
import LineListTopComponent from "./LineListTopComponent";
import LineSeparator from "./LineSeparator";
import LineWithOverlay from "./LineWithOverlay";

let scrollTimer = null;
let targetViewPosition = 0;
let previousY = 0;
let distanceFromBottom = 0;
let clearLineToReachTimer = null;
let isScrollingTimer = null;
let endScrollListeners = [];

function LinesList({
	game,
	isComments,
	disableLoading,
	isPreview,
	lines,
	activeSearch,
	dispatch,
	focusedType,
	users,
	openedParties,
	povCharacterId,
	hasPrevious,
	hasNext,
	loadingMoreLines,
	user,
	bookmark,
	currentPartyId,
	currentCharacter,
	characters,
	charactersArray,
	linesDisplayed
}) {
	const route = useRoute();
	let lineToReach = route.params?.lineToReach;
	let readerMode = route.params?.readerMode;
	let joining = route.params?.joining;

	const unreadLines = user?.unread_lines.filter((l) => l.game === game.id && l.is_comment === !!isComments) || [];

	const isLoadingMiddleOfStory = hasNext;

	const prevFocusedType = usePrevious(focusedType);
	const [useInverted, setuseInverted] = React.useState(!isWeb() && !hasNext);
	const [showJumpBottom, setshowJumpBottom] = React.useState(false);
	const [newContentBookmark, setnewContentBookmark] = React.useState(
		unreadLines?.length ? { line: unreadLines[0] } : null
	);
	const [readyToShow, setreadyToShow] = React.useState(!isWeb() || isLoadingMiddleOfStory);
	const [autoload, setautoload] = React.useState(!isWeb() || hasNext);
	const [pendingSnapToBottom, setpendingSnapToBottom] = React.useState(true);

	const navigation = useNavigation();

	const isScrollingRef = React.useRef();

	let linePosition = hasValue(route.params?.linePosition) ? route.params.linePosition : 1;

	if (useInverted) linePosition = 1 - linePosition;

	let readyToLoad =
		isComments ||
		!game.parties?.length ||
		povCharacterId ||
		(charactersArray.length && !charactersArray.some((c) => !c.deleted && !c.is_npc));

	const previousIsReady = usePrevious(readyToLoad);
	const flatlist = React.useRef();

	let filteredLines = React.useMemo(() => {
		if (!lines) return [];
		let returnLines = lines.slice();

		if (openedParties) {
			returnLines = returnLines.filter((l) => !l.party || openedParties.some((p) => p.id === l.party));
		}

		if (currentCharacter) {
			if (!isPlayer(game, user)) return returnLines;
			returnLines = returnLines.filter((l) => {
				if (l.author === currentCharacter.id) return true;
				if (l.whispered_to?.length) {
					return l.whispered_to.some((cId) => characters[cId]?.player?.id === user.id);
				}
				return true;
			});
		}

		return returnLines;
	}, [lines, currentCharacter?.id, user?.id, game.id, characters, openedParties]);

	const reversedList = React.useMemo(() => filteredLines.slice().reverse(), [filteredLines]);
	const lastFilteredLines = usePrevious(filteredLines);

	const localIsInGame = isInGame(user, game);

	const resetIsScrollingState = () => {
		clearTimeout(isScrollingTimer);
		isScrollingTimer = setTimeout(() => {
			isScrollingRef.current = false;
			endScrollListeners.forEach((cb) => cb());
			endScrollListeners = [];
		}, 500);
	};

	const addScrollEndListener = (listener) => {
		resetIsScrollingState();
		endScrollListeners.push(listener);
	};

	// CALL BACKS --------------------------------------------------------

	const refreshLine = React.useCallback((lineId) => dispatch(fetchLine(game.id, lineId)), [game.id]);
	const removeLine = React.useCallback(
		(line) => dispatch(removeLines({ gameId: game.id, lines: [{ id: line }] })),
		[game.id]
	);

	const receiveLine = React.useCallback(
		(line) => {
			if (line.game !== game.id) return;

			if (line.user && typeof line.user !== "object") {
				line.user = users[line.user];
			}

			// Grab the latest lines
			if (!hasNext) {
				loadBottom(true);

				if (!global.isFocused && !newContentBookmark) {
					setnewContentBookmark({ line });
				} else if (global.isFocused) {
					setnewContentBookmark(null);
				}
			}
		},
		[game.id, loadBottom, !hasNext, newContentBookmark]
	);

	const scrollToLine = React.useCallback(
		(lineId) => {
			if (lineId === 0) {
				if (useInverted) {
					flatlist.current?.scrollToEnd({ animated: true });
					setuseInverted(false);
				} else {
					flatlist.current?.scrollToOffset({ animated: true, offset: 0 });
				}
				return;
			}

			const usedArray = useInverted ? reversedList : filteredLines;
			let index = usedArray.findIndex((l) => l.id === lineId);

			if (index < 0 && usedArray.length) {
				// scroll to the line with the closest id to the target
				index = findIndexOfIdClosestTo(lineId, usedArray);
			}

			if (index > -1) {
				targetViewPosition = linePosition;
				flatlist.current?.scrollToIndex({ animated: true, index, viewPosition: linePosition });
			}
		},
		[filteredLines, reversedList, useInverted, linePosition]
	);

	const scrollToLineRef = React.useRef();
	scrollToLineRef.current = scrollToLine;

	const renderLine = React.useCallback(
		({ item, index, separators }) => (
			<LineWithOverlay
				line={item}
				lines={filteredLines}
				index={index}
				separators={separators}
				inverted={useInverted}
				isPreview={isPreview}
			/>
		),
		[filteredLines, focusedType, isComments, useInverted]
	);

	const renderSeparator = React.useCallback(
		(props) => (
			<LineSeparator
				{...props}
				lines={filteredLines}
				newContentBookmark={newContentBookmark}
				inverted={useInverted}
			/>
		),
		[filteredLines, newContentBookmark, useInverted]
	);

	const loadTop = React.useCallback(async () => {
		if (loadingMoreLines || disableLoading || !readyToLoad) return;

		if (!autoload) {
			setautoload(true);
		}
		const storyOnly = !localIsInGame;

		await dispatch(setLoadingMoreLines({ gameId: game.id, value: true }));
		await dispatch(fetchPreviousLines(game.id, storyOnly, isComments));
	}, [game.id, loadingMoreLines, isComments, localIsInGame, useInverted, autoload]);

	const loadBottom = React.useCallback(
		async (silent) => {
			if (loadingMoreLines || disableLoading || !readyToLoad) return;

			const storyOnly = !localIsInGame;

			if (!silent) {
				await dispatch(setLoadingMoreLines({ gameId: game.id, value: true }));
			}
			await dispatch(fetchNextLines(game.id, storyOnly, isComments));
		},
		[game.id, loadingMoreLines, isComments, localIsInGame, useInverted]
	);

	const previousUnreadLength = usePrevious(unreadLines.length);
	const previousHasNext = usePrevious(hasNext);

	const markLinesAsRead = React.useCallback(() => {
		if (localIsInGame && global.isFocused) {
			const lastLine = filteredLines.rg_last();
			if (!bookmark || !idEqual(lastLine, bookmark.line)) {
				dispatch(saveBookmark(game.id, lastLine));
			}
			if (
				unreadLines.length &&
				(!previousUnreadLength || previousUnreadLength < unreadLines.length || (previousHasNext && !hasNext))
			) {
				const upTo = hasNext ? filteredLines.rg_last().id : null;
				apiFetch(`games/${game.id}/lines/mark-read`, "POST", { party: currentPartyId, "up-to": upTo });
			}
		}
	}, [localIsInGame, filteredLines, dispatch, bookmark, game.id, unreadLines.length, currentPartyId, hasNext]);

	const onScreenTop = React.useCallback(() => {
		if (!autoload) return;
		if (hasPrevious && !lineToReach) {
			loadTop();
		}
	}, [hasPrevious, lineToReach, loadTop, autoload]);

	const onScreenBottom = React.useCallback(() => {
		if (hasNext) {
			if (!lineToReach || readerMode) {
				loadBottom();
			}
		} else {
			markLinesAsRead();
		}
	}, [hasNext, lineToReach, readerMode, loadBottom, markLinesAsRead]);

	const onStartReached = React.useCallback(() => {
		// Bottom of the screen
		if (useInverted) {
			onScreenBottom();
		}
		// Top of the screen
		else {
			onScreenTop();
		}
	}, [useInverted, onScreenTop, onScreenBottom]);

	const onEndReached = React.useCallback(() => {
		// Top of the screen
		if (useInverted) {
			onScreenTop();
		}
		// Bottom of the screen
		else {
			onScreenBottom();
		}
	}, [useInverted, onScreenTop, onScreenBottom]);

	// prettier-ignore
	const onScroll = React.useCallback(({ nativeEvent: { contentOffset: { y }, contentSize: { height }, layoutMeasurement: { height: layoutHeight } } }) => {
		resetIsScrollingState();
		isScrollingRef.current = true;

		if (isWeb()) {
			// Do not let the scroll stick to 0 as it stops the visible content from being maintained
			if (y === 0 && hasPrevious) {
				flatlist.current?.scrollToOffset({ animated: false, offset: 5 });
			}
		}
		const threshold = height * 0.15;
		if (y <= threshold && y < previousY) onStartReached();
		if (useInverted && !hasNext) {
			if (y > 2000 && !showJumpBottom) setshowJumpBottom(true);
			else if (y < 2000 && showJumpBottom) setshowJumpBottom(false);
		} else if (showJumpBottom) {
			setshowJumpBottom(false);
		}

		previousY = y;
		const scrollBottom = y + layoutHeight;
		distanceFromBottom = height - scrollBottom;

		if (lineToReach) {
			clearTimeout(clearLineToReachTimer);
			clearLineToReachTimer = setTimeout(() => navigation.setParams({ lineToReach: null, linePosition: null, readerMode: null }), 500);
		}
	}, [onStartReached, useInverted, hasNext, hasPrevious, showJumpBottom, lineToReach]);

	// EFFECTS --------------------------------------------------------

	React.useEffect(() => {
		socket.on("line_deleted", removeLine);
		socket.on("line_changed", refreshLine);
		socket.on("new_line", receiveLine);

		return () => {
			socket.off("line_changed", refreshLine);
			socket.off("new_line", receiveLine);
		};
	}, [receiveLine, removeLine, refreshLine]);

	React.useEffect(() => {
		if (isWeb()) {
			window.addEventListener("focus", markLinesAsRead);
			return () => {
				window.removeEventListener("focus", markLinesAsRead);
			};
		}
		const eventListener = AppState.addEventListener("change", markLinesAsRead);
		return () => {
			eventListener.remove();
		};
	}, [markLinesAsRead]);

	React.useEffect(() => {
		if (unreadLines.length) {
			markLinesAsRead();
		}
	}, [unreadLines.length, markLinesAsRead]);

	// Hack to work around initialScrollIndex not working on Web
	// see: https://github.com/facebook/react-native/issues/30387
	// also see comment on Flatlist below
	const scrollToBottom = React.useCallback(
		(animated) => {
			if (useInverted) {
				flatlist.current?.scrollToOffset({ animated, offset: 0 });
			} else {
				flatlist.current?.scrollToEnd({ animated });
			}
		},
		[useInverted]
	);

	const scrollToBottomAfterLoad = React.useCallback(() => {
		if (hasNext) {
			setreadyToShow(true);
			return;
		}
		const startTimer = () => {
			scrollTimer = setTimeout(() => {
				if (flatlist.current) {
					scrollToBottom();
					setTimeout(() => setreadyToShow(true), 100);
				} else {
					// recursively calls itself if flatlist ref is not set
					startTimer();
				}
			}, 1000);
		};

		startTimer();
		return () => {
			clearTimeout(scrollTimer);
		};
	}, [hasNext, useInverted, scrollToBottom]);

	React.useEffect(() => {
		if (disableLoading) return;

		if (isComments) {
			scrollToBottomAfterLoad();
			return;
		}

		const IsInitialLoad = (readyToLoad && !previousIsReady) || joining;

		if (IsInitialLoad && !lineToReach && lineToReach !== 0) {
			const params = { "opened-parties": (openedParties || []).map((p) => p.id) };
			// Load last lines if I am playing this game, otherwise load from the top
			if (localIsInGame) {
				params.to = "last";
			} else {
				params.from = 0;
				params.is_chat = false;
				if (useInverted) {
					setuseInverted(false);
				}
			}
			dispatch(fetchLines(game.id, params)).then(scrollToBottomAfterLoad);
		}
	}, [
		readyToLoad,
		game.id,
		openedParties,
		povCharacterId,
		isComments,
		lineToReach,
		localIsInGame,
		useInverted,
		scrollToBottomAfterLoad,
		joining,
	]);

	React.useEffect(() => {
		if (joining) {
			navigation.setParams({ joining: false });
		}
	}, [joining]);

	React.useEffect(() => {
		if (disableLoading) return;
		if (!isComments) return;
		dispatch(fetchLines(game.id, { to: "last" }, true));
	}, [isComments, disableLoading]);

	// The onScroll method is not called when the list is emptied. This make sure the scroll knows it was sent to the top
	React.useEffect(() => {
		if (!filteredLines?.length) previousY = 0;
	}, [filteredLines]);

	// Scroll to bottom if we just loaded the bottom of the game from an empty screen
	React.useEffect(() => {
		if (!lineToReach && readyToShow && !hasNext && !lastFilteredLines?.length && filteredLines?.length) {
			setTimeout(() => scrollToBottom(true), 1000);
		}
	}, [hasNext, filteredLines, readyToShow, lineToReach]);

	// Fill screen with more lines if there aren't enough
	React.useEffect(() => {
		if (filteredLines.length >= 30 || !lines?.length || loadingMoreLines) return () => null;
		if (!hasPrevious && !hasNext) return () => null;

		const loadMore = async () => {
			setreadyToShow(filteredLines.length > 0);
			// Save current position and move back to it
			if (hasPrevious) {
				await loadTop();
			} else if (hasNext) {
				await loadBottom();
			}

			if (lineToReach) {
				scrollToLineRef.current(lineToReach);
			}
			// On mobile, onscroll won't be called here if it is the last line in game.
			// It seems that it's because the scroll is already at the proper position
			addScrollEndListener(() => setreadyToShow(true));
		};

		loadMore();
	}, [lines, filteredLines, hasPrevious, hasNext, loadTop, loadBottom, lineToReach, loadingMoreLines]);

	const previousLinesDisplayed = usePrevious(linesDisplayed);

	React.useEffect(() => {
		if (previousLinesDisplayed == "mixed" && linesDisplayed != previousLinesDisplayed && !filteredLines.length) {
			if (hasPrevious) {
				loadTop();
			} else if (hasNext) {
				loadBottom();
			}
		}
	}, [linesDisplayed, filteredLines, hasPrevious, hasNext, loadTop, loadBottom]);

	React.useEffect(() => {
		if (filteredLines?.length > 0 && hasValue(lineToReach) && !isScrollingRef.current) {
			scrollToLineRef.current(lineToReach);
		}
	}, [lineToReach, filteredLines]);

	React.useEffect(() => {
		if (filteredLines.length) {
			if (prevFocusedType == null && !filteredLines.some((l) => isStory(l))) {
				dispatch(setFocusedType({ gameId: game.id, value: "chat" }));
			} else if (!focusedType) {
				dispatch(setFocusedType({ gameId: game.id, value: "story" }));
			}
		}
	}, [focusedType, filteredLines]);

	// jump to bottom on new message when maintain viewable is set
	React.useEffect(() => {
		if (!lastFilteredLines?.length) return () => null;

		const receivedLineBottom = lastFilteredLines.rg_last()?.id !== filteredLines.rg_last()?.id;

		if (!hasNext && receivedLineBottom && distanceFromBottom < 400) {
			setpendingSnapToBottom(true);
		}
	}, [filteredLines, useInverted, hasNext]);

	const snapToBottomIfNeeded = React.useCallback(() => {
		if (!isWeb() || !lastFilteredLines?.length) return;
		if (pendingSnapToBottom || distanceFromBottom < 100) {
			setTimeout(() => {
				flatlist.current?.scrollToEnd({ animated: false });
			}, 100);

			setpendingSnapToBottom(false);
		}
	}, [pendingSnapToBottom, lastFilteredLines]);

	const useInvertedRef = React.useRef();
	useInvertedRef.current = useInverted;

	const hasNextRef = React.useRef();
	hasNextRef.current = hasNext;

	// UseRef because the onViewableItemsChanged method cannot be changed on the fly
	const onViewableItemsChanged = React.useRef(({ viewableItems, changed }) => {
		if (isPreview || isComments) {
			return;
		}

		//FYI: const {index, item, key, isViewable} = viewableItems[x];
		let top = viewableItems.rg_first()?.item;
		let bottom = viewableItems.rg_last()?.item;
		if (useInvertedRef.current) {
			top = viewableItems.rg_last()?.item;
			bottom = viewableItems.rg_first()?.item; // list in inverted, so first viewableItems is at the bottom.
		}
		global.currentLine = bottom;

		if (!top) {
			return;
		}

		let scene;
		if (!hasNextRef.current && distanceFromBottom < 100) {
			scene = getLastScene(bottom.game);
		} else {
			scene = getMostRecentScene(bottom);
		}

		const state = store.getState();
		const gameUI = state.gamesUI[game.id] || {};
		if (!idEqual(scene, gameUI.currentStoryMarker)) {
			dispatch(setCurrentStoryMarker({ gameId: bottom.game, value: scene }));
		}

		const parties = state.parties[game.id].all;
		const shouldHavePartiesOnScreen = parties.some(
			(p) => (!p.closing_line_id || p.closing_line_id > top.id) && p.separation_line_id < bottom.id
		);

		if (shouldHavePartiesOnScreen !== gameUI.hasPartiesOnScreen) {
			dispatch(setHasPartiesOnScreen({ gameId: bottom.game, value: shouldHavePartiesOnScreen }));
		}
	});

	const viewConfigRef = {
		itemVisiblePercentThreshold: 50,
		waitForInteraction: false,
		minimumViewTime: 500,
	};

	const BottomComponent = (
		<LineListBottomComponent
			game={game}
			useInverted={useInverted}
			lines={filteredLines}
			loadingMoreLines={loadingMoreLines}
			isComments={isComments}
			hasNext={hasNext}
			loadBottom={loadBottom}
		/>
	);

	const TopComponent = (
		<LineListTopComponent
			hasPrevious={hasPrevious}
			isPreview={isPreview}
			isComments={isComments}
			lines={filteredLines}
			game={game}
			useInverted={useInverted}
			loadTop={loadTop}
			newContentBookmark={newContentBookmark}
			loadingMoreLines={loadingMoreLines}
		/>
	);

	const footerComponent = React.useMemo(
		() => (
			<>
				{loadingMoreLines && <AppActivityIndicator show />}
				{useInverted ? TopComponent : BottomComponent}
			</>
		),
		[TopComponent, BottomComponent, useInverted, loadingMoreLines]
	);

	// Have this call to force redraw when object resizes
	const { onLayout } = useLayout();

	const contentContainerStyle = {
		flexGrow: 1,
		justifyContent: useInverted ? "flex-start" : "flex-end",
		...ws({ paddingHorizontal: gameScreenPadding() }),
	};

	const usedList = React.useMemo(
		() => (useInverted ? reversedList : filteredLines),
		[useInverted, reversedList, filteredLines]
	);


	if (!usedList.length) return <ActivityIndicator style={{ padding: 32 }} color={global.colors.hint} />;

	return (
		<>
			<MCVPFlatList
				// data
				ref={flatlist}
				viewabilityConfig={viewConfigRef.current}
				onViewableItemsChanged={onViewableItemsChanged.current}
				// initialScrollToIndex won't display more than 11 items on web, so it cannot be used
				// it won't appear as an issue if the 11 starting items are enough to fill the screen, as scrolling will load more content and "fix" it.
				// but it won't even let users scroll if the 11 items don't force a scrollbar to appear
				// https://github.com/facebook/react-native/issues/30387
				// initialScrollIndex={isWeb() && !hasNext ? usedList.length - 1 : null}
				// style
				contentContainerStyle={[contentContainerStyle, readyToShow ? null : { opacity: 0 }]}
				// options
				extraData={focusedType}
				data={usedList}
				inverted={useInverted}
				bounces={false}
				// the value in minIndexForVisible doesn't matter on web.
				// It will always try to maintain the position of item with the lowest index visible on screen
				// https://github.com/necolas/react-native-web/issues/1880
				maintainVisibleContentPosition={hasNext ? { minIndexForVisible: 0 } : null}
				// Render
				ListHeaderComponent={useInverted ? BottomComponent : TopComponent}
				renderItem={renderLine}
				ItemSeparatorComponent={isComments ? null : renderSeparator}
				ListFooterComponent={footerComponent}
				ListEmptyComponent={<ActivityIndicator style={{ padding: 32 }} color={global.colors.hint} />}
				// Methods
				onLayout={onLayout}
				keyExtractor={(item, index) => String(item.id)}
				onScrollToIndexFailed={(error) => {
					// flatlist.current.scrollToOffset({ offset: error.averageItemLength * error.index, animated: true });
					setTimeout(() => {
						if (usedList.length !== 0 && flatlist.current !== null) {
							flatlist.current.scrollToIndex({
								index: error.index,
								animated: true,
								viewPosition: targetViewPosition,
							});
						}
					}, 100);
				}}
				onEndReached={onEndReached}
				onEndReachedThreshold={0.75}
				onScroll={onScroll}
				onContentSizeChange={snapToBottomIfNeeded}
			/>
			<CondView show={showJumpBottom}>
				<JumpToBottomButton
					onPress={() => {
						setshowJumpBottom(false);
						if (useInverted) flatlist.current.scrollToOffset({ animated: true, offset: 0 });
						else flatlist.current?.scrollToEnd();
					}}
				/>
			</CondView>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.game.id;
	const gameUI = state.gamesUI[gameId] || {};
	let lines =
		ownProps.lines || (ownProps.isComments ? state.commentLines[gameId] : getLinesDisplayed(state, gameId));

	if(!!ownProps.activeSearch || ownProps.searchedLines?.length > 0) {
		lines = !!ownProps.searchedLines ? ownProps.searchedLines : [];
	}

	const boundaries = state.gameLinesBoundaries[gameId];

	let firstLineId = ownProps.isComments ? boundaries?.firstCommentId : boundaries?.firstLineId;
	let lastLineId = ownProps.isComments ? boundaries?.lastCommentId : boundaries?.lastLineId;

	const loadedLines = ownProps.isComments ? state.commentLines[gameId] : state.lines[gameId];

	let hasPrevious =
		!ownProps.disableLoading && firstLineId && (!loadedLines?.length || loadedLines?.rg_first()?.id > firstLineId);
	let hasNext =
		!ownProps.disableLoading && lastLineId && (!loadedLines?.length || loadedLines?.rg_last()?.id < lastLineId);

	if (!loadedLines) {
		hasPrevious = !!firstLineId;
		hasNext = !ownProps.localIsInGame;
	}

	return {
		lines,
		focusedType: gameUI.focusedType,
		users: state.users,
		user: state.user,
		openedParties: ownProps.lines ? null : state.parties[gameId]?.openedParties,
		povCharacterId: ownProps.lines ? null : state.parties[gameId]?.povCharacterId,
		hasPrevious,
		hasNext,
		loadingMoreLines: gameUI.loadingMoreLines,
		bookmark: state.bookmarkForGames[gameId],
		currentPartyId: state.parties[gameId]?.current?.id,
		currentCharacter: getCurrentCharacter(state),
		characters: state.characters,
		charactersArray: state.charactersByGame[gameId] || Array.rg_empty,
		linesDisplayed: gameUI.linesDisplayed,
	};
};

export default connect(mapStateToProps)(LinesList);
