import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from "moment";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { setMacrosHistory } from "../../store/slices/gamesUISlice";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { sizes } from "../../styles/font";
import { BuildStyleMethod } from "../../styles/theming";
import { isValidCommand, sendRoll } from "../../tools/diceRoll";
import { ws } from "../../tools/generic";
import { SELECT_CHARACTERS_REQUEST_MACRO } from "../characters/SelectCharactersModal";
import AppText from "../generic/AppText";
import ModalScreen from "../generic/modal/ModalScreen";
import CondView from "../meta/CondView";
import RGTokenIcon from "../RGTokenIcon";
import MacroVisualizer from "./MacroVisualizer";
import { isGM } from "../../tools/games";


function RollMacroModal({
	currentCharacter,
	gameId,
	partyId,
	sheetValuePairs,
	route,
	navigation,
	dispatch,
	characterSelected,
	gmInfo,
	game,
}) {
	const styles = stylesMethod(global.theme);
	const { macro, isRequest, charactersSelected, timer } = route.params;

	console.log("route params", route.params);

	const [targets, setTargets] = React.useState([]);
	const [selectedLabel, setselectedLabel] = React.useState();

	const [toGMOnly, setToGMOnly] = React.useState(false);

	const labels = React.useMemo(() => JSON.parse(macro.labels), [macro.labels]);

	const gm = isGM(gameId);

	React.useEffect(() => {
		dispatch(fetchSheetValuePairsIfNeeded(currentCharacter?.sheet || game.sheet_template));
	}, []);

	const setCharacterAsTarget = React.useCallback(
		(character) => {
			if (!character) return;
			const target = { label: selectedLabel, character };

			const newTargets = targets.rg_replaceElement(
				(t) => t.label.targetId === selectedLabel.targetId,
				target,
				true
			);
			setTargets(newTargets);
			dispatch(fetchSheetValuePairsIfNeeded(character?.sheet));
		},
		[selectedLabel, targets]
	);

	const sendRequest = React.useCallback(()=>{

		const sheet_value_targets = targets.map(t => ({...t, character: t.character.id}))

		apiFetch(`games/${gameId}/roll-requests`, "POST",
		{
			name: macro.name,
			command: macro.command,
			targets: charactersSelected.map((c) => c.id),
			timer: timer,
			sheet_value_targets: JSON.stringify(sheet_value_targets),
		})
		navigation.navigate("Game");

	}, [charactersSelected, timer, gameId, macro, targets]);

	const roll = React.useCallback(() => {
		sendRoll(gameId, partyId, macro.command, macro.name, targets, currentCharacter, toGMOnly, gmInfo, dispatch);

		async function updateHistory() {
			let history = await AsyncStorage.getItem("macrosHistory");
			history = history ? JSON.parse(history) : { [gameId]: {} };
			history[gameId] = history[gameId] || {};
			history[gameId][macro.id] = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
			AsyncStorage.setItem("macrosHistory", JSON.stringify(history));
			dispatch(setMacrosHistory({ gameId, value: history[gameId] }));
		}

		updateHistory();

		navigation.navigate("Game");
	}, [targets, macro.id, macro.command, gameId, partyId, currentCharacter, dispatch]);

	React.useEffect(() => {
		setCharacterAsTarget(characterSelected);
	}, [characterSelected]);

	const [isValid, error] = isValidCommand(macro.command, targets, currentCharacter);

	const noTargetLabels = labels.filter(
		(l) => l.targetId > 0 && !targets.some((t) => t.label.targetId === l.targetId)
	);
	

	React.useEffect(()=>{
		if (charactersSelected?.length && isRequest && isValid && !noTargetLabels.length) {
			sendRequest();
		}
	}, [charactersSelected, isRequest, isValid, noTargetLabels, sendRequest]);

	const options = [
		{
			title: _("Back"),
			onPress: () => navigation.goBack(),
		},
	];

	// If not the GM, add the option to whisper to the GM
	!gm && options.unshift(
		{
			title: _("To GM only"),
			type: "checkbox", // Type is checked in ButtonFooter.
			onChange: () => { setToGMOnly(!toGMOnly); },
			checked: { toGMOnly },
		}
	);

	isRequest &&
		options.push({
			title: _("Choose characters", "request roll"),
			disabled: !isValid || !!noTargetLabels.length,
			onPress: () => {
				navigation.navigate("SelectRequestTargets", {
					pcOnly: true,
					goToScreen: "SetTimerModal",
					gameId,
					nextButtonTitle: _("Set timer", "set timer for roll request"),
					code: SELECT_CHARACTERS_REQUEST_MACRO,
				});
			},
		});

	!isRequest &&
		options.push({
			title: _("Roll"),
			onPress: roll,
			disabled: !isValid || !!noTargetLabels.length,
			allowLegendaryShare: true
		});

	const stillLoadingTargetSheetValues = targets.some((t) => !sheetValuePairs[t.character.sheet]);

	return (
		<ModalScreen
			wide
			options={options}
			useButtonFooter
			title={
				<View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: 16, marginVertical: 8 }}>
					<RGTokenIcon
						name={macro.icon}
						color={global.colors.userColors[macro.color]}
						size={sizes.average}
						style={{ marginRight: 8 }}
					/>
					<AppText bold>{macro.name}</AppText>
				</View>
			}
		>
			<CondView show={noTargetLabels.length} style={{ marginBottom: 16 }}></CondView>
			<MacroVisualizer
				author={currentCharacter}
				command={macro.command}
				labels={labels}
				onLabelClicked={(label) => {
					if (label.targetId <= 0) return;
					setselectedLabel(label);
					navigation.navigate("SelectCharacter", { sendToScreen: "RollMacroModal" });
				}}
				targets={targets}
				style={{ marginBottom: 16 }}
			/>

			<CondView show={error || !!noTargetLabels.length}>
				<Divider style={{ marginBottom: 8 }} />
				<AppText color="secondary" hide={!noTargetLabels.length} style={styles.info}>
					{ws(
						_("Click on the values in red to assign a target.", "roll macro hint", {
							label_name: noTargetLabels[0]?.value,
						}),
						_("Tap the values in red to assign a target.", "roll macro hint", {
							label_name: noTargetLabels[0]?.value,
						})
					)}
				</AppText>
				<AppText hide={!error || !!noTargetLabels.length || stillLoadingTargetSheetValues} color="danger">
					{error}
				</AppText>
			</CondView>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	const game = state.games[gameId];
	const gmInfo = state.games[gameId]?.game_master;
	return {
		gameId,
		game: game,
		currentCharacter: getCurrentCharacter(state),
		partyId: state.parties[gameId]?.current?.id,
		sheetValuePairs: state.sheetValuePairs, // used to force rerender when new values are received
		characterSelected: state.characters[ownProps.route.params?.characterSelectedId],
		gmInfo,
	};
};

export default connect(mapStateToProps)(RollMacroModal);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	info: {
		padding: 8,
		marginHorizontal: -8,
		borderWidth: 1,
		borderColor: colors.lightBorder,
	},
}));
